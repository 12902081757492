import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client';

import { Apollo, gql } from 'apollo-angular';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GraphqlService {

	constructor(private apollo: Apollo) { }

	public query<T>(query: string, variables?: any): Promise<ApolloQueryResult<T>> {
		return lastValueFrom(this.apollo.query<T>({
			query: gql(query),
			variables,
		}));
	}

	public mutate<T>(mutation: string, variables?: any): Promise<FetchResult<T>> {
		return lastValueFrom(this.apollo.mutate<T>({
			mutation: gql(mutation),
			variables,
		}));
	}
}
