import { Component } from '@angular/core';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { WebSuccessMetrics } from 'src/app/shared/models';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-web-success-metrics',
	templateUrl: './success-metrics.component.html',
	styleUrl: './success-metrics.component.scss'
})
export class SuccessMetricsComponent extends AbstractPageForm {

	public webSuccessMetrics: WebSuccessMetrics;

	constructor(
		private graphqlService: GraphqlService
	) {
		super();
	}

	public override async loadPageData(): Promise<void> {
		const result = await this.graphqlService.query<{
			webSuccessMetrics: WebSuccessMetrics
		}>(`
			query GetWebSuccessMetrics {
				webSuccessMetrics {
					totalKwhs
					totalTherms
					totalSatisfiedCustomers
				}
			}
		`);

		this.webSuccessMetrics = result.data.webSuccessMetrics;
	}
}
