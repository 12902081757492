	<div class="row maxwidth-1080 mx-auto">
		<div class="col-12 col-lg-4 mb-4">
			<div class="row">
				<div class="col">
					<p class="text-center mb-2"><img src="https://assets.powerkiosk.com/web/images/icon-thumb-up.svg" alt="Thumb up" class="icon-img"></p>
				</div>
			</div>
			<div class="row">
				<div class="col company-stat">
					<p id="satisfiedCust" class="big-number">19,491</p>
					<p class="smaller-text">satisfied customers</p>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-4 mb-4">
			<div class="row">
				<div class="col">
					<p class="text-center mb-2"><img src="https://assets.powerkiosk.com/web/images/icon-calendar.svg" alt="Calendar" class="icon-img"></p>
				</div>
			</div>
			<div class="row">
				<div class="col company-stat">
					<p id="yrsInBusiness" class="big-number">12</p>
					<p class="smaller-text">years in business</p>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-4 mb-4">
			<div class="row">
				<div class="col">
					<p class="text-center mb-2"><img src="https://assets.powerkiosk.com/web/images/icon-chart-money.svg" alt="Chart money" class="icon-img"></p>
				</div>
			</div>
			<div class="row">
				<div class="col company-stat">
					<p id="assetsUnderMgt" class="big-number">$1,234,567</p>
					<p class="smaller-text">assets under management</p>
				</div>
			</div>
		</div>
	</div>
